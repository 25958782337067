<template>
  <!-- modal -->
  <a-modal
    v-model:visible="show"
    destroy-on-close
    width="800px"
    :mask-closable="false"
    @cancel="cancel"
    :title="title"
  >
    <a-form class="myform" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="ID Role">
        <a-input disabled v-model:value="record.id"></a-input>
      </a-form-item>
      <a-form-item label="Nama Role">
        <a-input v-model:value="record.name" disabled></a-input>
      </a-form-item>
    </a-form>

    <md-table :data-source="menus" :columns="columns" row-key="id" :loading="isLoadData">
      <template #no="{ index }">
        <span>
          {{ 0 + index }}
        </span>
      </template>
      <template #tipe="{ text }">
        <span>
          {{ text !== null ? 'Sub Menu' : 'Menu Utama' }}
        </span>
      </template>
      <template #menu_utama="{ text }">
        <span>
          {{ text !== null ? menus.find(i => i.id === text).name : '' }}
        </span>
      </template>
      <template #assigned="{ record }">
        <span>
          <a-checkbox
            v-model:checked="record.assigned"
            @change="event => checkChange(event, record)"
          />
        </span>
      </template>
    </md-table>

    <template #footer>
      <a-button key="submit" @click="submit" type="primary">Simpan</a-button>
      <a-button key="cancel" @click="show = false">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { inject, toRefs, ref, onMounted } from 'vue'

import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'Deskripsi Menu',
    dataIndex: 'name',
  },

  {
    title: 'Assign',
    dataIndex: 'assigned',
    slots: { customRender: 'assigned' },
  },
]

export default {
  setup() {
    const show = inject('showMenuModal')
    const showState = inject('showState')
    const refresh = inject('refresh')

    const cancel = e => {
      show.value = false
    }

    const dataRoles = ref(null)
    const menus = ref([])

    // id menu       tipe          menu Utama (parent)    assigned

    const flattenArr = arr => {
      const result = []
      arr.forEach(item => {
        const { id, name, parent_id, assigned, children } = item

        result.push({
          id,
          name,
          parent_id,
          assigned,
        })
        if (children) result.push(...flattenArr(children))
      })
      return result
    }
    const isLoadData = ref(false)
    onMounted(() => {
      isLoadData.value = true
      apiClient
        .get(`/api/roles/${showState.record.id}?expand=menus`)
        .then(({ data }) => {
          dataRoles.value = data
          menus.value = data.menus // flattenArr(data.menus)
        })
        .catch(e => {
          message.error('Gagal Mengambil Data Role!')
          console.error(e)
        })
        .finally(() => (isLoadData.value = false))
    })

    const submit = e => {
      const flatMenu = flattenArr(menus.value)
      apiClient
        .post(`/api/roles/${showState.record.id}/assign-menu`, { menus: flatMenu })
        .then(({ data }) => {
          message.success('Berhasil disimpan')
          show.value = false
        })
        .catch(e => {
          message.error('Gagal Menyimpan!')
          console.error(e)
        })
        .finally(() => (isLoadData.value = false))
    }

    const checkChange = (e, record) => {
      const checked = e.target.checked
      const toChecked = record => {
        record.assigned = checked
        if (Array.isArray(record.children)) {
          record.children.forEach(e => {
            toChecked(e)
          })
        }
      }

      toChecked(record)

      const findItemNested = (arr, itemId, nestingKey = 'children') =>
        arr.reduce((a, item) => {
          if (a) return a
          if (item.id === itemId) return item
          if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
        }, null)
      if (e.target.checked === false) {
        const parent = findItemNested(menus.value, record.parent_id)
        if (parent) parent.assigned = false
      }
    }

    return {
      visible: true,
      show,
      submit,
      cancel,
      ...toRefs(showState),
      dataRoles,
      menus,
      columns,
      isLoadData,
      checkChange,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
