export const columns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },

  {
    title: 'Nama Role',
    dataIndex: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'active',
    slots: { customRender: 'status' },
  },
  {
    title: 'Level',
    dataIndex: 'level',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
