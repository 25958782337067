<template>
  <!-- modal -->
  <a-modal
    v-model:visible="show"
    destroy-on-close
    width="800px"
    :mask-closable="false"
    @cancel="cancel"
    :title="title"
  >
    <a-form class="myform" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="ID Role">
        <a-input disabled v-model:value="record.id"></a-input>
      </a-form-item>
      <a-form-item label="Nama Role">
        <a-input v-model:value="record.name" disabled></a-input>
      </a-form-item>
    </a-form>

    <md-table
      :data-source="menus"
      :columns="columns"
      row-key="name"
      :loading="isLoadData"
      children-column-name="actions"
    >
      <template #assigned="{ record }">
        <span v-if="record.assigned !== undefined">
          <a-checkbox v-model:checked="record.assigned" />
        </span>
      </template>
    </md-table>

    <template #footer>
      <a-button key="submit" @click="submit" type="primary">Simpan</a-button>
      <a-button key="cancel" @click="show = false">Cancel</a-button>
    </template>
  </a-modal>
</template>

<script>
import { inject, toRefs, ref, onMounted, computed } from 'vue'

import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'Action',
    dataIndex: 'label',
  },
  {
    title: 'Assign',
    dataIndex: 'assigned',
    slots: { customRender: 'assigned' },
  },
]

export default {
  setup() {
    const show = inject('showMenuAction')
    const showState = inject('showState')
    const refresh = inject('refresh')

    const cancel = e => {
      show.value = false
    }

    const dataRoles = ref(null)
    const menus = ref([])

    const flattenArr = arr => {
      const result = []
      arr.forEach(item => {
        const { name, assigned, actions } = item

        if (assigned !== undefined && assigned !== null)
          result.push({
            name,
            assigned,
          })
        if (actions) result.push(...flattenArr(actions))
      })
      return result
    }
    const isLoadData = ref(false)
    onMounted(() => {
      isLoadData.value = true
      apiClient
        .get(`/api/roles/${showState.record.id}?expand=actions`)
        .then(({ data }) => {
          dataRoles.value = data
          menus.value = data.actions
        })
        .catch(e => {
          message.error('Gagal Mengambil Data Role!')
          console.error(e)
        })
        .finally(() => (isLoadData.value = false))
    })

    const submit = e => {
      apiClient
        .post(`/api/roles/${showState.record.id}/assign-action`, { actions: flattend.value })
        .then(({ data }) => {
          message.success('Berhasil disimpan')
          show.value = false
        })
        .catch(e => {
          message.error('Gagal Menyimpan!')
          console.error(e)
        })
        .finally(() => (isLoadData.value = false))
    }

    const flattend = computed(() => flattenArr(menus.value))

    return {
      visible: true,
      show,
      submit,
      cancel,
      ...toRefs(showState),
      dataRoles,
      menus,
      columns,
      isLoadData,
      flattend,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
