<template>
  <div class="">
    <div class="table-responsive text-nowrap">
      <md-table
        v-if="meta"
        :columns="columns"
        :data-source="source.items"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Role">
              <a-button class="button" size="small" @click="lihatRole(record)">
                <i class="fe fe-eye" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Ubah Role">
              <a-button class="button" size="small" @click="ubahRole(record)">
                <i class="fe fe-edit" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Atur Menu">
              <a-button class="button" size="small" @click="aturMenu(record)">
                <i class="fe fe-menu" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Atur Izin">
              <a-button class="button" size="small" @click="aturIzin(record)">
                <i class="fa fa-key" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Pemetaan Role">
              <a-button
                class="button"
                size="small"
                @click="
                  () => {
                    router.push({
                      name: 'lihat-role',
                      query: { role_id: record.id },
                    })
                  }
                "
              >
                <i class="fe fe-users" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Hapus Role">
              <a-button class="button" size="small" danger @click="hapusRole(record)">
                <i class="fe fe-user-minus" />
              </a-button>
            </a-tooltip>
          </span>
        </template>
        <template #status="{ text }">
          <span
            :class="[
              text === true
                ? 'font-size-12 badge badge-primary'
                : 'font-size-12 badge badge-default',
            ]"
            >{{ text ? 'Aktif' : 'Non Aktif' }}</span
          >
        </template>
      </md-table>
    </div>
  </div>
</template>

<script>
import { columns } from './columns'

import { Modal, message } from 'ant-design-vue'

import { inject, ref, computed } from 'vue'
import apiClient from '@/services/axios'

import { useRouter } from 'vue-router'

export default {
  setup(props) {
    const router = useRouter()
    const source = inject('source')
    const show = inject('show')
    const showMenuModal = inject('showMenuModal')
    const showMenuAction = inject('showMenuAction')
    const showState = inject('showState')
    const params = inject('params')
    const refresh = inject('refresh')

    const meta = computed(() => source.value._meta)

    const startRow = ref(1)
    const handleTableChange = (pag, filters, sorter) => {
      params.page = pag.current
      params.perPage = pag.pageSize
      refresh()
      startRow.value = (pag.current - 1) * pag.pageSize + 1
    }
    const lihatRole = record => {
      show.value = true
      showState.title = 'Lihat Role'
      showState.record = record
      showState.readOnly = true
      showState.state = 'show'
    }

    const ubahRole = record => {
      show.value = true
      showState.title = 'Ubah Role'
      showState.record = record
      showState.state = 'edit'
      showState.readOnly = false
      source.items = []
    }
    const aturMenu = record => {
      showMenuModal.value = true
      showState.title = 'Atur Menu'
      showState.state = 'aturMenu'
      showState.record = record
    }

    const aturIzin = record => {
      console.log('aturIzin ', record)
      showMenuAction.value = true
      showState.title = 'Atur Izin'
      showState.state = 'aturIzin'
      showState.record = record
    }

    const hapusRole = record => {
      Modal.confirm({
        title: 'Konfirmasi Hapus Role',
        content: 'Apakah anda ingin hapus role ini?',
        onOk() {
          apiClient
            .delete('/api/roles/' + record.id)
            .then(({ data }) => {
              refresh()
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    return {
      source,
      columns,
      meta,
      startRow,
      handleTableChange,
      show,
      showState,
      lihatRole,
      ubahRole,
      showMenuModal,
      aturMenu,
      aturIzin,
      showMenuAction,
      hapusRole,
      params,
      router,
    }
  },
}
</script>

<style scoped>
.button {
  margin: 0 4px;
}
</style>
