<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <!-- TODO: Komponen Filter Tabel -->
            <m-filter @update:filter="updateFilter" v-model:loading="loading" />
            <m-table />
          </div>
        </div>
      </div>
    </div>
    <m-modal v-if="show"></m-modal>
    <menu-modal v-if="showMenuModal"></menu-modal>
    <menu-action v-if="showMenuAction"></menu-action>
  </div>
</template>

<script>
import MTable from './components/Forms/Table' // local component
import MFilter from './components/Forms/Filter' // local component
import apiClient from '@/services/axios'
import MModal from './modal'
import MenuModal from './modalMenu'
import MenuAction from './modalAction'

import { provide, ref, onMounted, reactive, computed } from 'vue'
export default {
  name: 'VbPeranPengguna',
  components: {
    MFilter,
    MTable,
    MModal,
    MenuModal,
    MenuAction,
  },
  setup() {
    const source = ref({})
    const show = ref(false)
    const showMenuModal = ref(false)
    const showMenuAction = ref(false)
    const showState = reactive({ title: '' })
    const params = reactive({
      page: 1,
      perPage: 10,
    })

    const loading = ref(false)

    const refresh = (param = {}) => {
      loading.value = true
      apiClient
        .get('/api/roles', {
          params: {
            page: params.page,
            'per-page': params.perPage,
            ...param,
          },
        })
        .then(({ data }) => {
          source.value = data
        })
        .finally(() => {
          loading.value = false
        })
    }

    onMounted(() => {
      refresh()
    })

    provide(
      'source',
      computed(() => source.value),
    )
    provide('show', show)
    provide('showState', showState)
    provide('showMenuModal', showMenuModal)
    provide('showMenuAction', showMenuAction)
    provide('refresh', refresh)
    provide('params', params)

    const search = q => refresh(q)
    const filter = ref({})
    const updateFilter = f => {
      filter.value = Object.assign({}, f, { time_requested: new Date() })
      refresh(filter.value)
    }
    return { show, showMenuModal, params, showMenuAction, search, filter, loading, updateFilter }
  },
}
</script>
